@lost gutter 50px;
* {
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  max-width: 100vw;
  overflow-x: hidden;
}
html.noScrolling {
  overflow-y: hidden;
}
body {
  display: block;
  width: 100%;
  max-width: 100vw;
  min-height: 100%;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 300;
  font-size: 110%;
  line-height: 1.5em;
  color: #1e1e1e;
  background-color: #fff;
  overflow-x: hidden;
}
@media screen and (max-width: 800px) {
  body {
    font-size: 103%;
    line-height: 1.4em;
  }
}
h1,
h2,
h3 {
  font-weight: 500;
  text-transform: uppercase;
}
h1,
h2 {
  font-size: 2em;
  line-height: 1.2em;
  margin-bottom: 0.7em;
}
h1+h3,
h2+h3 {
  position: relative;
  top: -0.7em;
}
@media screen and (max-width: 800px) {
  h1,
  h2 {
    font-size: 1.85em;
  }
}
@media screen and (max-width: 400px) {
  h1,
  h2 {
    font-size: 1.75em;
  }
}
@media screen and (max-width: 500px) {
  h1,
  h2 {
    hyphens: auto;
  }
}
h3 {
  font-size: 1.6em;
  line-height: 1.3em;
}
h3+p {
  margin-top: 0.7em;
}
@media screen and (max-width: 800px) {
  h3 {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 400px) {
  h3 {
    font-size: 1.25em;
  }
}
h4 {
  font-size: 1.05em;
  font-weight: 500;
  line-height: inherit;
  letter-spacing: 0.02em;
  margin-bottom: 1em;
}
strong {
  font-weight: 500;
}
p {
  margin-bottom: 1.5em;
}
a {
  color: #870337;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a,
a:hover,
a:active,
a:focus,
a:visited {
  outline: none;
}
.introText {
  font-size: 1.15em;
  line-height: 1.4em;
}
.introText ul li {
  padding-left: 17px;
}
.introText ul li::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: relative;
  top: -4px;
  left: -17px;
  margin-right: -7px;
  background-color: #1e1e1e;
}
@media screen and (max-width: 800px) {
  .introText {
    font-size: 1.15em;
    line-height: 1.45em;
  }
}
@media screen and (max-width: 400px) {
  .introText {
    font-size: 1.1em;
  }
}
ul {
  list-style: none;
}
@media screen and (max-width: 400px) {
  h1,
  h2,
  h3,
  h4,
  strong,
  p,
  a {
    hyphens: auto;
  }
  strong,
  p {
    text-align: justify;
    text-justify: inter-character;
  }
}
header {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  top: 0;
  box-shadow: 0 2px 5px rgba(0,0,0,0.5);
  z-index: 50;
}
header #headerTop {
  display: flex;
  justify-content: space-between;
  height: 65px;
  background-color: rgba(255,255,255,0.95);
  padding: 10px 50px;
  transition: all 0.5s ease-in-out;
}
header #headerTop a {
  display: block;
  height: 100%;
  text-decoration: none;
}
header #headerTop a img {
  height: 100%;
  filter: brightness(80%);
}
header #headerTop a .claim {
  font-style: italic;
  color: #989898;
  position: relative;
  top: -12px;
  margin-left: 20px;
  transition: top 0.3s ease-in-out;
}
@media screen and (max-width: 650px) {
  header #headerTop a .claim {
    display: none;
  }
}
header #headerTop > div {
  display: flex;
  align-items: center;
}
header #headerTop > div #languages {
  position: relative;
  top: -1px;
}
header #headerTop > div #languages ul {
  display: flex;
  list-style: none;
}
header #headerTop > div #languages ul li a {
  display: block;
  position: relative;
  top: 3px;
  font-size: 0.85em;
  color: #626262;
  text-transform: uppercase;
  padding: 3px;
}
header #headerTop > div #languages ul li.isActive a {
  color: #870337;
}
header #headerTop > div .hamburger {
  position: relative;
  top: 3px;
  padding-right: 0;
}
header nav.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 0;
  background-color: rgba(255,255,255,0.95);
  padding: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease-in-out;
}
header nav.menu a {
  text-decoration: none;
}
header nav.menu > ul.pages {
  margin-bottom: 30px;
}
header nav.menu ul.pages {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  transition-delay: 0.2s;
}
header nav.menu ul.pages li {
  text-align: center;
}
header nav.menu ul.pages li a {
  display: block;
  font-size: 1.1em;
  font-weight: 400;
  color: inherit;
  text-transform: uppercase;
  padding: 12px 10px 8px 10px;
}
header nav.menu ul.pages li a:after {
  content: '';
  display: block;
  width: 0;
  height: 10px;
  background-color: #e9e9e9;
  margin: 2px auto;
  transition: width 0.3s ease-in-out;
}
header nav.menu ul.pages li a:hover:after {
  width: 100%;
}
header nav.menu ul.pages li ul li a {
  font-weight: 300;
  text-transform: initial;
  padding: 5px 10px 15px 10px;
}
header nav.menu ul.pages li ul li a:after {
  height: 3px;
}
header.smaller #headerTop {
  height: 45px;
  padding: 5px 50px;
}
header.smaller #headerTop a .claim {
  top: -10px;
}
header.isActive nav.menu {
  height: auto;
  max-height: 100vh;
  padding: 100px 50px 150px 50px;
}
header.isActive nav.menu ul {
  opacity: 1;
}
@media screen and (max-width: 1000px) {
  header #headerTop {
    padding: 10px 30px;
  }
  header.smaller #headerTop {
    padding: 5px 30px;
  }
}
@media screen and (max-width: 700px) {
  header #headerTop {
    height: 72px;
    padding: 10px 20px;
  }
  header #headerTop a .claim {
    top: -14px;
  }
  header.smaller #headerTop {
    height: 45px;
    padding: 5px 20px;
  }
  header.smaller #headerTop a .claim {
    top: -10px;
  }
}
@media screen and (max-width: 550px) {
  header #headerTop .claim {
    display: none;
  }
}
@media screen and (max-width: 400px) {
  header #headerTop {
    height: 65px;
    padding: 10px 15px;
  }
  header.smaller #headerTop {
    padding: 5px 15px;
  }
}
main {
  display: block;
}
section.sectionContainer {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 200vw;
  position: relative;
  left: 0;
  overflow-x: hidden;
}
@media screen and (max-width: 1000px) {
  section.sectionContainer {
    flex-direction: column;
    width: 100vw;
  }
}
section.section,
div.section {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  background-size: cover;
  background-attachment: fixed;
  padding: 100px 100px 100px 50px;
}
@media screen and (max-width: 1200px) {
  section.section,
  div.section {
    background-attachment: scroll;
  }
}
section.section.main,
div.section.main {
  justify-content: flex-start;
  align-items: flex-start;
  padding: 200px 50px 50px 50px;
  background-attachment: scroll;
}
section.section.category,
div.section.category {
/* Il background-attachment fixed non funziona su Safari nè su tablet né su cellulare.
    Per questo motivo rimesso background-attachment scroll a partire dai 1200px */
  justify-content: flex-start;
  align-items: flex-start;
  padding: 100px 50px;
  background-attachment: scroll;
}
section.section.history,
div.section.history {
  justify-content: center;
  align-items: center;
  background-attachment: scroll;
}
@media screen and (max-width: 800px) {
  section.section.history .intro,
  div.section.history .intro {
    width: 95%;
  }
}
@media screen and (max-width: 1600px) {
  section.section.main,
  div.section.main {
    padding: 170px 50px 50px 50px;
  }
}
@media screen and (max-width: 1400px) {
  section.section,
  div.section {
    padding: 100px 100px 50px 50px;
  }
  section.section.main,
  div.section.main {
    padding: 150px 50px 50px 50px;
  }
  section.section.category,
  div.section.category,
  section.section.history,
  div.section.history {
    padding: 100px 50px 50px 50px;
  }
}
@media screen and (max-width: 1200px) {
  section.section,
  div.section {
    padding: 30px;
  }
  section.section.main,
  div.section.main {
    padding: 120px 30px 50px 30px;
  }
  section.section.category,
  div.section.category,
  section.section.history,
  div.section.history {
    padding: 70px 30px 30px 30px;
  }
}
@media screen and (max-width: 600px) {
  section.section,
  div.section {
    padding: 20px 20px 25px 20px;
  }
  section.section.main,
  div.section.main {
    padding: 100px 20px 25px 20px;
  }
  section.section.category,
  div.section.category,
  section.section.history,
  div.section.history {
    background-attachment: scroll;
    padding: 70px 20px 25px 20px;
  }
}
@media screen and (max-width: 400px) {
  section.section,
  div.section {
    padding: 15px 15px 20px 15px;
  }
  section.section.main,
  div.section.main {
    padding: 90px 15px 20px 15px;
  }
  section.section.category,
  div.section.category,
  section.section.history,
  div.section.history {
    padding: 70px 15px 20px 15px;
  }
}
section.section.description,
div.section.description {
  align-items: flex-start;
  position: relative;
  padding: 3%;
}
section.section.description *,
div.section.description * {
  color: #fff;
}
section.section.description a,
div.section.description a {
  color: #fbb900;
}
section.section.description p,
div.section.description p,
section.section.description ul,
div.section.description ul,
section.section.description ol,
div.section.description ol {
  font-weight: 300;
}
section.section.description ul,
div.section.description ul,
section.section.description ol,
div.section.description ol {
  margin-bottom: 1.5em;
}
section.section.description p + ul,
div.section.description p + ul {
  margin-top: 0;
}
section.section.description li,
div.section.description li {
  padding-left: 17px;
}
section.section.description li::before,
div.section.description li::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: relative;
  top: -4px;
  left: -17px;
  margin-right: -7px;
  background-color: #fff;
}
@media screen and (max-width: 1000px) {
  section.section.description,
  div.section.description {
    min-height: initial;
    padding: 40px 30px;
  }
  section.section.description h4,
  div.section.description h4 {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  section.section.description,
  div.section.description {
    padding: 30px 20px;
  }
}
@media screen and (max-width: 400px) {
  section.section.description,
  div.section.description {
    padding: 20px 15px;
  }
}
section.section .desaturate,
div.section .desaturate,
section.section .background,
div.section .background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
}
section.section .desaturate,
div.section .desaturate {
  background-size: cover;
  filter: grayscale(100%);
}
section.section .content,
div.section .content {
  display: flex;
  width: 100%;
  z-index: 10;
}
section.section .content > .gallery,
div.section .content > .gallery {
  display: block;
  width: 100%;
  height: 100%;
  lost-column: 2/3;
  order: 1;
}
section.section .content > .text,
div.section .content > .text {
  display: block;
  width: 100%;
  height: 100%;
  lost-column: 1/3;
  order: 2;
}
section.section .content > .text > *:last-child,
div.section .content > .text > *:last-child {
  margin-bottom: 0;
}
section.section .content.visible,
div.section .content.visible {
  display: flex;
}
@media screen and (max-width: 1400px) {
  section.section .content > .gallery,
  div.section .content > .gallery {
    lost-column: 3/5;
  }
  section.section .content > .text,
  div.section .content > .text {
    lost-column: 2/5;
  }
}
@media screen and (max-width: 900px) {
  section.section .content,
  div.section .content {
    flex-direction: column;
  }
  section.section .content > .text,
  div.section .content > .text {
    order: 1;
    lost-column: 1;
  }
  section.section .content > .gallery,
  div.section .content > .gallery {
    order: 2;
    lost-column: 1;
  }
}
.intro {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: auto;
  border-style: solid;
  border-image-slice: 150;
  border-image-width: 16px;
  background-color: rgba(255,255,255,0.75);
  z-index: 15;
}
.intro *:last-child {
  margin-bottom: 0;
}
.intro.categories {
  width: 45%;
}
.intro.categories .claim {
  display: none;
  font-style: italic;
  color: #989898;
  margin-left: 0;
  margin-bottom: 20px;
}
@media screen and (max-width: 650px) {
  .intro.categories .claim {
    display: block;
  }
}
.intro.subcategories {
  width: 60%;
}
.intro.categories,
.intro.subcategories,
.intro.showroom {
  border-width: 12px;
  padding: 30px;
}
@media screen and (max-width: 600px) {
  .intro.categories,
  .intro.subcategories,
  .intro.showroom {
    padding: 20px;
  }
}
@media screen and (max-width: 400px) {
  .intro.categories,
  .intro.subcategories,
  .intro.showroom {
    padding: 15px;
  }
}
@media screen and (max-width: 1200px) {
  .intro.categories {
    width: 52.5%;
  }
  .intro.subcategories {
    width: 67.5%;
  }
}
@media screen and (max-width: 1000px) {
  .intro.categories {
    width: 60%;
    border-width: 14px;
  }
  .intro.subcategories {
    width: 75%;
    border-width: 8px;
  }
}
@media screen and (max-width: 800px) {
  .intro.categories {
    width: 70%;
    border-width: 12px;
  }
  .intro.subcategories {
    width: 85%;
    border-width: 6px;
  }
}
@media screen and (max-width: 600px) {
  .intro {
    background-color: rgba(255,255,255,0.8);
  }
  .intro.categories {
    width: 80%;
    border-width: 10px;
  }
  .intro.subcategories {
    width: 100%;
    border-width: 6px;
  }
}
@media screen and (max-width: 400px) {
  .intro {
    background-color: rgba(255,255,255,0.85);
  }
  .intro.categories {
    width: 100%;
    border-width: 8px;
  }
  .intro.subcategories {
    border-width: 6px;
  }
}
.intro .submenu {
  display: block;
  margin-top: 20px;
}
.intro .submenu ul {
  display: inline-block;
  list-style: none;
  font-size: 0.95em;
}
.intro .submenu ul li a {
  display: block;
  color: #4b4b4b;
  transition: color 0.15s ease-in-out;
}
.intro .submenu ul li a::after {
  content: '';
  display: inline-block;
  width: 3px;
  height: 3px;
  position: relative;
  top: -3px;
  background-color: #4b4b4b;
  border-radius: 50%;
  margin: 0 7px;
}
.intro .submenu ul li a:hover {
  color: #870337;
}
.intro .submenu ul:last-child a::after {
  display: none;
}
.arrowNew {
  display: block;
  width: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  background-size: 23px;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 0.2s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
  z-index: 10;
}
.arrowNew.right {
  right: 0;
  background-image: url("../images/right-arrow_simple.svg");
}
.arrowNew.left {
  left: 0;
  background-image: url("../images/left-arrow_simple.svg");
  background-color: rgba(255,255,255,0.2);
}
.arrowNew:hover {
  opacity: 1;
}
@media screen and (max-width: 1000px) {
  .arrowNew {
    display: none;
  }
}
.default {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 50px;
}
.default.documents {
  background-image: linear-gradient(rgba(170,170,170,0.075), rgba(170,170,170,0.75));
}
.default.googleMap {
  flex-direction: column;
  justify-content: flex-start;
  background-image: none;
  padding: 80px 50px;
}
@media screen and (max-width: 800px) {
  .default.googleMap {
    padding: 70px 20px;
  }
}
.default > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1060px;
}
.default > div p:last-child {
  margin-bottom: 0;
}
.default > div p,
.default > div h2 {
  text-align: center;
}
.default #address {
  text-align: center;
  margin-top: 30px;
}
.default #address h4,
.default #address p {
  margin-bottom: 1.4em;
}
@media screen and (max-width: 1000px) {
  .default {
    padding: 30px;
  }
  .default.googleMap {
    padding: 60px 30px;
  }
}
@media screen and (max-width: 700px) {
  .default {
    padding: 20px;
  }
  .default.googleMap {
    padding: 50px 20px;
  }
}
@media screen and (max-width: 400px) {
  .default {
    padding: 15px;
  }
  .default.googleMap {
    padding: 45px 15px;
  }
}
.map iframe {
  display: block;
  position: relative;
  width: 100% !important;
  height: 650px;
  background-color: #fff;
  border: 5px solid #fff;
}
@media screen and (max-width: 1000px) {
  .map iframe {
    height: 600px;
  }
}
@media screen and (max-width: 700px) {
  .map iframe {
    height: 500px;
  }
}
@media screen and (max-width: 400px) {
  .map iframe {
    height: 450px;
  }
}
.owl-carousel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.owl-carousel .owl-stage-outer,
.owl-carousel .owl-stage,
.owl-carousel .owl-item,
.owl-carousel .slide {
  height: 100%;
  background-size: cover;
}
.owl-carousel .slideGradient {
  display: block;
  width: 100%;
  height: 100%;
}
.owl-carousel .owl-dots {
  display: none;
  justify-content: center;
  position: relative;
  top: -35px;
  z-index: 15;
}
.owl-carousel .owl-dots .owl-dot {
  padding: 6px !important;
}
.owl-carousel .owl-dots .owl-dot span {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 5px 0px rgba(0,0,0,0.7);
}
.owl-carousel .owl-dots .owl-dot.active span {
  background-color: #fbb900;
}
section.main.expoCamorino .intro {
  border-color: #7d868f;
  order: 1;
}
section.main.expoCamorino .owl-carousel {
  order: 2;
}
section.main.expoCamorino .owl-carousel .owl-dots {
  display: none;
}
@media screen and (max-width: 1000px) {
  section.main.expoCamorino {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #7d868f;
  }
  section.main.expoCamorino .intro {
    position: relative;
    width: calc(100% - (2 * 30px));
    margin: 70px 30px;
    border-color: #fff;
  }
  section.main.expoCamorino .owl-carousel {
    position: relative;
    height: auto;
    min-height: auto;
    padding-bottom: 60%;
  }
  section.main.expoCamorino .owl-carousel .owl-stage-outer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media screen and (max-width: 800px) {
  section.main.expoCamorino .intro {
    width: calc(100% - (2 * 20px));
    margin: 70px 20px;
  }
  section.main.expoCamorino .owl-carousel {
    padding-bottom: 65%;
  }
}
@media screen and (max-width: 600px) {
  section.main.expoCamorino .owl-carousel {
    padding-bottom: 70%;
  }
}
@media screen and (max-width: 500px) {
  section.main.expoCamorino .owl-carousel {
    padding-bottom: 75%;
  }
}
.documents {
  display: flex;
  flex-direction: column;
}
.documents > div {
  padding: 40px;
  border: 14px solid rgba(255,255,255,0.8);
}
.documents h2 {
  margin-bottom: 1.5em;
}
@media screen and (max-width: 800px) {
  .documents {
    padding: 70px 20px;
  }
}
@media screen and (max-width: 500px) {
  .documents > div {
    padding: 20px;
  }
}
.documentsList {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #b0b0b0;
  margin-bottom: 10px;
}
.documentsList a {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 6px 0 5px 20px;
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid #b0b0b0;
  background-color: rgba(255,255,255,0);
  transition: background-color 0.1s linear;
}
.documentsList a > div:nth-of-type(1) {
  width: calc(100% - (125px + 60px));
  padding-right: 20px;
}
.documentsList a > div:nth-of-type(2) {
  width: 125px;
  font-size: 0.85em;
  text-align: right;
}
.documentsList a > div:nth-of-type(3) {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 60px;
  padding: 0 20px 5px 20px;
}
.documentsList a > div img {
  height: 18px;
}
@media screen and (max-width: 500px) {
  .documentsList a {
    padding: 6px 0 5px 5px;
  }
  .documentsList a > div {
    hyphens: none;
  }
  .documentsList a > div:nth-of-type(1) {
    width: calc(100% - (70px + 26px));
  }
  .documentsList a > div:nth-of-type(2) {
    width: 70px;
    padding-right: 5px;
  }
  .documentsList a > div:nth-of-type(3) {
    width: 26px;
    padding: 0 5px 5px 5px;
  }
}
.documentsList a:hover {
  background-color: rgba(255,255,255,0.35);
}
.gallery a {
  display: block;
  lost-column: 1/4 4 10px;
  height: auto;
  padding-bottom: 15%;
  background-size: cover;
  margin-bottom: 10px;
  transition: filter 0.5s ease-in-out;
  position: relative;
}
.gallery a:hover {
  filter: grayscale(100%);
}
@media screen and (max-width: 1400px) {
  .gallery a {
    lost-column: 1/3 3 10px;
    padding-bottom: 20%;
    border-width: 4px;
  }
}
@media screen and (max-width: 1000px) {
  .gallery a {
    border-width: 3px;
    padding-bottom: 25%;
    lost-column: 1/2 2 10px;
  }
}
@media screen and (max-width: 900px) {
  .gallery {
    margin-top: 40px;
  }
  .gallery a {
    padding-bottom: 15%;
    lost-column: 1/4 4 10px;
  }
}
@media screen and (max-width: 700px) {
  .gallery a {
    padding-bottom: 20%;
    lost-column: 1/3 3 10px;
  }
}
@media screen and (max-width: 500px) {
  .gallery a {
    padding-bottom: 30%;
    lost-column: 1/2 2 10px;
  }
}
@media screen and (max-width: 350px) {
  .gallery a {
    padding-bottom: 50%;
    lost-column: 1 10px;
  }
}
footer {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  color: #fff;
  font-size: 0.75em;
  background-color: #1e1e1e;
}
footer > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1060px;
  height: 100%;
  margin: 0 auto;
}
footer p {
  margin-bottom: 0;
  margin-right: 30px;
}
footer p:last-of-type {
  margin-right: 0;
}
footer a {
  color: #fff;
}
footer a:hover {
  color: inherit;
  text-decoration: underline;
}
@media screen and (max-width: 700px) {
  footer {
    padding: 25px 20px 30px 20px;
  }
  footer > div:not(.social) {
    flex-direction: column;
    align-items: center;
  }
  footer p {
    margin-right: 0;
  }
}
@media screen and (max-width: 400px) {
  footer {
    padding: 20px 15px 25px 15px;
  }
}
footer .social {
  display: flex;
  height: 44px;
  position: absolute;
  top: -54px;
  left: 0;
  right: 0;
  z-index: 45;
}
footer .social a.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: rgba(255,255,255,0.7);
  background-color: #1e1e1e;
  padding: 7px;
  transition: background-color 0.15s ease-in-out;
  margin-right: 5px;
}
footer .social a.icon img {
  display: block;
  width: 100%;
  height: 100%;
}
footer .social a.icon:last-of-type {
  margin-right: 0;
}
footer .social a.icon:hover {
  background-color: #fff;
  background-color: #6d6d6d;
}
