/* COLORS VARIABLES
  ======================================================= */
white = rgb(255, 255, 255)
black = rgb(0, 0, 0)
text = rgb(30, 30, 30)
lightGrey = rgb(204, 203, 203)
darkGrey = rgb(152, 152, 152)
bordeaux = rgb(135, 3, 55)
yellow = rgb(251, 185, 0)



/* INITIAL & BODY
  ======================================================= */
*
  border 0
  margin 0
  padding 0
  box-sizing border-box

html
  max-width 100vw
  overflow-x hidden
  &.noScrolling
    overflow-y hidden

body
  display block
  width 100%
  max-width 100vw
  min-height 100%
  font-family 'Ubuntu', sans-serif;
  font-weight 300
  font-size 110%
  line-height 1.5em
  color text
  background-color white
  overflow-x hidden
  @media screen and (max-width 800px)
    font-size 103%
    line-height 1.4em



/* TEXTS
  ======================================================= */
h1
h2
h3
  font-weight 500
  text-transform uppercase

h1
h2
  font-size 2em
  line-height 1.2em
  margin-bottom 0.7em
  &+h3
    position relative
    top -0.7em
  @media screen and (max-width 800px)
    font-size 1.85em
  @media screen and (max-width 400px)
    font-size 1.75em
  @media screen and (max-width 500px)
    hyphens auto

h3
  font-size 1.6em
  line-height 1.3em
  &+p
    margin-top 0.7em
  @media screen and (max-width 800px)
    font-size 1.3em
  @media screen and (max-width 400px)
    font-size 1.25em

h4
  font-size 1.05em
  font-weight 500
  line-height inherit
  letter-spacing 0.02em
  margin-bottom 1em

strong
  font-weight 500

p
  margin-bottom 1.5em

a
  color bordeaux
  text-decoration none
  &:hover
    text-decoration underline

a
a:hover
a:active
a:focus
a:visited
  outline none

.introText
  font-size 1.15em
  line-height 1.4em
  ul
    li
      padding-left 17px
      &::before
        content ''
        display inline-block
        width 5px
        height 5px
        border-radius 50%
        position relative
        top -4px
        left -17px
        margin-right -7px
        background-color text
  @media screen and (max-width 800px)
    font-size 1.15em
    line-height 1.45em
  @media screen and (max-width 400px)
    font-size 1.1em

ul
  list-style none


@media screen and (max-width 400px)
  h1
  h2
  h3
  h4
  strong
  p
  a
    hyphens auto
  strong
  p
    text-align justify
    text-justify inter-character



/* HEADER
  ======================================================= */
header
  display flex
  flex-direction column
  width 100%
  position fixed
  top 0
  box-shadow 0 2px 5px rgba(0, 0, 0, 0.5)
  z-index 50
  #headerTop
    display flex
    justify-content space-between
    height 65px
    background-color rgba(255, 255, 255, 0.95)
    padding 10px 50px
    transition all 0.5s ease-in-out
    a
      display block
      height 100%
      text-decoration none
      img
        height 100%
        filter brightness(80%)
      .claim
        font-style italic
        color darkGrey
        position relative
        top -12px
        margin-left 20px
        transition top 0.3s ease-in-out
        @media screen and (max-width 650px)
          display none
    > div
      display flex
      align-items center
      #languages
        position relative
        top -1px
        ul
          display flex
          list-style none
          li
            a
              display block
              position relative
              top 3px
              font-size 0.85em
              color lighten(text, 30%)
              text-transform uppercase
              padding 3px
            &.isActive
              a
                color bordeaux
      .hamburger
        position relative
        top 3px
        padding-right 0
  
  nav.menu
    display flex
    flex-direction column
    justify-content center
    max-height 0
    background-color rgba(255, 255, 255, 0.95)
    padding 0
    overflow-y scroll
    -webkit-overflow-scrolling touch
    transition all 0.3s ease-in-out
    a
      text-decoration none
    > ul.pages
      margin-bottom 30px
    ul.pages
      opacity 0
      transition opacity 0.3s ease-in-out
      transition-delay 0.2s
      li
        text-align center
        a
          display block
          font-size 1.1em
          font-weight 400
          color inherit
          text-transform uppercase
          padding 12px 10px 8px 10px
          &:after
            content ''
            display block
            width 0
            height 10px
            background-color lighten(text, 90%)
            margin 2px auto
            transition width 0.3s ease-in-out
          &:hover:after
            width 100%
        ul
          li
            a
              font-weight 300
              text-transform initial
              padding 5px 10px 15px 10px
              &:after
                height 3px

  &.smaller
    #headerTop
      height 45px
      padding 5px 50px
      a
        .claim
          top -10px
  &.isActive
    nav.menu
      height auto
      max-height 100vh
      padding 100px 50px 150px 50px
      ul
        opacity 1

  @media screen and (max-width 1000px)
    #headerTop
      padding 10px 30px
    &.smaller
      #headerTop
        padding 5px 30px
  @media screen and (max-width 700px)
    #headerTop
      height 72px
      padding 10px 20px
      a
        .claim
          top -14px
    &.smaller
      #headerTop
        height 45px
        padding 5px 20px
        a
          .claim
            top -10px
  @media screen and (max-width 550px)
    #headerTop
      .claim
        display none
  @media screen and (max-width 400px)
    #headerTop
      height 65px
      padding 10px 15px
    &.smaller
      #headerTop
        padding 5px 15px



/* CONTAINER & CONTENT
  ======================================================= */
main
  display block

section.sectionContainer
  display flex
  align-items stretch
  justify-content space-between
  flex-wrap nowrap
  width 200vw
  position relative
  left 0
  overflow-x hidden
  @media screen and (max-width 1000px)
    flex-direction column
    width 100vw

section.section
div.section
  display flex
  width 100vw
  min-height 100vh
  justify-content flex-end
  align-items flex-end
  position relative
  background-size cover
  background-attachment fixed
  padding 100px 100px 100px 50px
  @media screen and (max-width 1200px)
    background-attachment scroll
  &.main
    justify-content flex-start
    align-items flex-start
    padding 200px 50px 50px 50px
    background-attachment scroll
  &.category
    /* Il background-attachment fixed non funziona su Safari nè su tablet né su cellulare.
    Per questo motivo rimesso background-attachment scroll a partire dai 1200px */
    justify-content flex-start
    align-items flex-start
    padding 100px 50px
    background-attachment scroll
  &.history
    justify-content center
    align-items center
    background-attachment scroll
    @media screen and (max-width 800px)
      .intro
        width 95%
  @media screen and (max-width 1600px)
    &.main
      padding 170px 50px 50px 50px
  @media screen and (max-width 1400px)
    padding 100px 100px 50px 50px
    &.main
      padding 150px 50px 50px 50px
    &.category
    &.history
      padding 100px 50px 50px 50px
  @media screen and (max-width 1200px)
    padding 30px
    &.main
      padding 120px 30px 50px 30px
    &.category
    &.history
      padding 70px 30px 30px 30px
  @media screen and (max-width 600px)
    padding 20px 20px 25px 20px
    &.main
      padding 100px 20px 25px 20px
    &.category
    &.history
      background-attachment scroll
      padding 70px 20px 25px 20px
  @media screen and (max-width 400px)
    padding 15px 15px 20px 15px
    &.main
      padding 90px 15px 20px 15px
    &.category
    &.history
      padding 70px 15px 20px 15px
  
  &.description
    align-items flex-start
    position relative
    padding 3%
    *
      color white
    a
      color yellow
    p
    ul
    ol
      font-weight 300
    ul
    ol
      margin-bottom 1.5em
    p + ul
      margin-top 0
    li
      padding-left 17px
      &::before
        content ''
        display inline-block
        width 5px
        height 5px
        border-radius 50%
        position relative
        top -4px
        left -17px
        margin-right -7px
        background-color white
    @media screen and (max-width 1000px)
      min-height initial
      padding 40px 30px
      h4
        display none
    @media screen and (max-width 700px)
      padding 30px 20px
    @media screen and (max-width 400px)
      padding 20px 15px
  
  .desaturate
  .background
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    z-index 5
  .desaturate
    background-size cover
    filter grayscale(100%)
  
  .content
    display flex
    width 100%
    z-index 10
    > .gallery
      display block
      width 100%
      height 100%
      lost-column 2/3
      order 1
    > .text
      display block
      width 100%
      height 100%
      lost-column 1/3
      order 2
      > *:last-child
        margin-bottom 0
    &.visible
      display flex
    @media screen and (max-width 1400px)
      > .gallery
        lost-column 3/5
      > .text
        lost-column 2/5
    @media screen and (max-width 900px)
      flex-direction column
      > .text
        order 1
        lost-column 1
      > .gallery
        order 2
        lost-column 1

.intro
  display flex
  flex-direction column
  justify-content flex-end
  height auto
  border-style solid
  border-image-slice 150
  border-image-width 16px
  background-color rgba(255, 255, 255, 0.75)
  z-index 15
  *:last-child
    margin-bottom 0
  &.categories
    width 45%
    .claim
      display none
      font-style italic
      color darkGrey
      margin-left 0
      margin-bottom 20px
      @media screen and (max-width 650px)
        display block
  &.subcategories
    width 60%
  &.categories
  &.subcategories
  &.showroom
    border-width 12px
    padding 30px
    @media screen and (max-width 600px)
      padding 20px
    @media screen and (max-width 400px)
      padding 15px
  @media screen and (max-width 1200px)
    &.categories
      width 52.5%
    &.subcategories
      width 67.5%
  @media screen and (max-width 1000px)
    &.categories
      width 60%
      border-width 14px
    &.subcategories
      width 75%
      border-width 8px
  @media screen and (max-width 800px)
    &.categories
      width 70%
      border-width 12px
    &.subcategories
      width 85%
      border-width 6px
  @media screen and (max-width 600px)
    background-color rgba(255, 255, 255, 0.8)
    &.categories
      width 80%
      border-width 10px
    &.subcategories
      width 100%
      border-width 6px
  @media screen and (max-width 400px)
    background-color rgba(255, 255, 255, 0.85)
    &.categories
      width 100%
      border-width 8px
    &.subcategories
      border-width 6px
  
  .submenu
    display block
    margin-top 20px
    ul
      display inline-block
      list-style none
      font-size 0.95em
      li
        a
          display block
          color lighten(text, 20%)
          transition color 0.15s ease-in-out
          &::after
            content ''
            display inline-block
            width 3px
            height 3px
            position relative
            top -3px
            background-color lighten(text, 20%)
            border-radius 50%
            margin 0 7px
          &:hover
            color bordeaux
      &:last-child
        a::after
          display none


.arrowNew
  display block
  width 50px
  position absolute
  top 0
  bottom 0
  background-size 23px
  background-position center
  background-repeat no-repeat
  transition opacity 0.2s ease-in-out
  opacity 0.7
  cursor pointer
  z-index 10
  &.right
    right 0
    background-image url('../images/right-arrow_simple.svg')
  &.left
    left 0
    background-image url('../images/left-arrow_simple.svg')
    background-color rgba(255, 255, 255, 0.2)
  &:hover
    opacity 1
  @media screen and (max-width 1000px)
    display none



/* SEZIONI AGGIUNTIVE
  ======================================================= */
.default
  display flex
  justify-content center
  align-items center
  width 100%
  min-height 100vh
  padding 50px
  &.documents
    // background-color rgb(204, 203, 203)
    background-image linear-gradient(rgba(170, 170, 170, 0.075), rgba(170, 170, 170, 0.75))
  &.googleMap
    flex-direction column
    justify-content flex-start
    background-image none
    padding 80px 50px
    @media screen and (max-width 800px)
      padding 70px 20px
  > div
    display flex
    flex-direction column
    width 100%
    max-width 1060px
    p:last-child
      margin-bottom 0
    p
    h2
      text-align center
  #address
    text-align center
    margin-top 30px
    h4
    p
      margin-bottom 1.4em
  @media screen and (max-width 1000px)
    padding 30px
    &.googleMap
      padding 60px 30px
  @media screen and (max-width 700px)
    padding 20px
    &.googleMap
      padding 50px 20px
  @media screen and (max-width 400px)
    padding 15px
    &.googleMap
      padding 45px 15px


.map iframe
  display block
  position relative
  width 100% !important
  height 650px
  background-color white
  border 5px solid white
  @media screen and (max-width 1000px)
    height 600px
  @media screen and (max-width 700px)
    height 500px
  @media screen and (max-width 400px)
    height 450px



/* SLIDESHOW & GALLERY
  ======================================================= */
.owl-carousel
  position absolute
  top 0
  right 0
  bottom 0
  left 0
  .owl-stage-outer
  .owl-stage
  .owl-item
  .slide
    height 100%
    background-size cover
  .slideGradient
    display block
    width 100%
    height 100%
  .owl-dots
    display none
    //display flex
    justify-content center
    position relative
    top -35px
    z-index 15
    .owl-dot
      padding 6px !important
      span
        display block
        width 12px
        height 12px
        border-radius 50%
        background-color white
        box-shadow 0 0 5px 0px rgba(0, 0, 0, 0.7)
      &.active
        span
          background-color yellow

section.main.expoCamorino
  .intro
    border-color #7d868f
    order 1
  .owl-carousel
    order 2
    .owl-dots
      display none
  @media screen and (max-width 1000px)
    display flex
    flex-direction column
    padding 0
    background-color #7d868f
    .intro
      position relative
      width calc(100% - (2 * 30px))
      margin 70px 30px
      border-color #ffffff
    .owl-carousel
      position relative
      height auto
      min-height auto
      padding-bottom 60%
      .owl-stage-outer
        position absolute
        top 0
        right 0
        bottom 0
        left 0
  @media screen and (max-width 800px)
    .intro
      width calc(100% - (2 * 20px))
      margin 70px 20px
    .owl-carousel
      padding-bottom 65%
  @media screen and (max-width 600px)
    .owl-carousel
      padding-bottom 70%
  @media screen and (max-width 500px)
    .owl-carousel
      padding-bottom 75%
      

.documents
  display flex
  flex-direction column
  > div
    padding 40px
    border 14px solid rgba(255, 255, 255, 0.8)
  h2
    margin-bottom 1.5em
  @media screen and (max-width 800px)
    padding 70px 20px
  @media screen and (max-width 500px)
    > div
      padding 20px

.documentsList
  display flex
  flex-direction column
  border-top 1px solid lighten(text, 65%)
  margin-bottom 10px
  a
    display flex
    align-items flex-end
    justify-content space-between
    padding 6px 0 5px 20px
    color inherit
    text-decoration none
    border-bottom 1px solid lighten(text, 65%)
    background-color rgba(255, 255, 255, 0)
    transition background-color 0.1s linear
    > div
      &:nth-of-type(1)
        width calc(100% - (125px + 60px))
        padding-right 20px
      &:nth-of-type(2)
        width 125px
        font-size 0.85em
        text-align right
      &:nth-of-type(3)
        display flex
        align-items flex-end
        justify-content flex-end
        width 60px
        padding 0 20px 5px 20px
      img
        height 18px
    @media screen and (max-width 500px)
      padding 6px 0 5px 5px
      > div
        hyphens none
        &:nth-of-type(1)
          width calc(100% - (70px + 26px))
        &:nth-of-type(2)
          width 70px
          padding-right 5px
        &:nth-of-type(3)
          width 26px
          padding 0 5px 5px 5px
    &:hover
      background-color rgba(255, 255, 255, 0.35)


.gallery
  a
    display block
    lost-column 1/4 4 10px
    height auto
    padding-bottom 15%
    background-size cover
    margin-bottom 10px
    transition filter 0.5s ease-in-out
    position relative
    &:hover
      filter grayscale(100%)
  @media screen and (max-width 1400px)
    a
      lost-column 1/3 3 10px
      padding-bottom 20%
      border-width 4px
  @media screen and (max-width 1000px)
    a
      border-width 3px
      padding-bottom 25%
      lost-column 1/2 2 10px
  @media screen and (max-width 900px)
    margin-top 40px
    a
      padding-bottom 15%
      lost-column 1/4 4 10px
  @media screen and (max-width 700px)
    a
      padding-bottom 20%
      lost-column 1/3 3 10px
  @media screen and (max-width 500px)
    a
      padding-bottom 30%
      lost-column 1/2 2 10px
  @media screen and (max-width 350px)
    a
      padding-bottom 50%
      lost-column 1 10px



/* FOOTER & MAP
  ======================================================= */
footer
  display block
  position relative
  width 100%
  height 80px
  color white
  font-size 0.75em
  background-color text
  > div
    display flex
    justify-content center
    align-items center
    width 100%
    max-width 1060px
    height 100%
    margin 0 auto
  p
    margin-bottom 0
    margin-right 30px
    &:last-of-type
      margin-right 0
  a
    color white
    &:hover
      color inherit
      text-decoration underline
  
  @media screen and (max-width 700px)
    padding 25px 20px 30px 20px
    > div:not(.social)
      flex-direction column
      align-items center
    p
      margin-right 0
  @media screen and (max-width 400px)
    padding 20px 15px 25px 15px
    
  .social
    display flex
    height 44px
    position absolute
    top -54px
    left 0
    right 0
    z-index 45
    a.icon
      display flex
      justify-content center
      align-items center
      width 44px
      height 44px
      background-color rgba(255, 255, 255, 0.7)
      background-color text
      padding 7px
      transition background-color 0.15s ease-in-out
      margin-right 5px
      img
        display block
        width 100%
        height 100%
      &:last-of-type
        margin-right 0
      &:hover
        background-color rgba(255, 255, 255, 1)
        background-color lighten(text, 35%)